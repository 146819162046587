import React from 'react'
import { Partner, UserConfig } from 'types'

import { State, ActionType } from '../constants'

type SetMasqueradePartnerActionPayload = { activePartner: Partner }

export const reducer: React.Reducer<
  State,
  | { type: ActionType.SetInitialPartner; payload: UserConfig }
  | {
      type: ActionType.SetMasqueradePartner
      payload: SetMasqueradePartnerActionPayload
    }
  | {
      type: ActionType.SetMasqueradePartnerId
      payload: string
    }
> = (state, action) => {
  switch (action.type) {
    case ActionType.SetInitialPartner:
      return {
        ...state,
        partner: action.payload.activePartner,
        availablePartners: action.payload.partners,
      }

    case ActionType.SetMasqueradePartnerId:
      return {
        ...state,
        masqueradePartnerId: action.payload,
      }

    case ActionType.SetMasqueradePartner:
      return {
        ...state,
        partner: action.payload.activePartner,
      }

    default:
      return state
  }
}

export const setInitialPartner =
  (
    dispatch: React.Dispatch<{
      type: ActionType.SetInitialPartner
      payload: UserConfig
    }>
  ) =>
  (payload: UserConfig): void =>
    dispatch({
      type: ActionType.SetInitialPartner,
      payload,
    })

export const setMasqueradePartnerId =
  (
    dispatch: React.Dispatch<{
      type: ActionType.SetMasqueradePartnerId
      payload: string
    }>
  ) =>
  (payload: string): void =>
    dispatch({
      type: ActionType.SetMasqueradePartnerId,
      payload,
    })

export const setMasqueradePartner =
  (
    dispatch: React.Dispatch<{
      type: ActionType.SetMasqueradePartner
      payload: SetMasqueradePartnerActionPayload
    }>
  ) =>
  (payload: SetMasqueradePartnerActionPayload): void =>
    dispatch({
      type: ActionType.SetMasqueradePartner,
      payload,
    })
