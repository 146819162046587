/**
 * @fileoverview Alternate header component used in for the AI marketing
 * assistant views. Note that the overall layout for this component has been
 * copied from the existing Header component. Ideally we would refactor that
 * component and extract the page specific parts (e.g. the different navigation)
 * so that we can have a single overall Header component that can be used with
 * multiple different navigation components.
 */
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Wrapper, Button, Image } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import { useAuth } from 'lib/hooks/useAuth'
import { Routes } from 'lib/constants'
import { PartnerSwitcher } from 'components/Header/PartnerSwitcher'
import { PartnerName } from 'components/Header/PartnerName'
import { GlobalContext } from 'store/global'

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const StyledBackgroundImageWrapper = styled(Wrapper)`
  position: absolute;
  inset: 0;
  overflow: hidden;
`

const StyledBackgroundImage = styled(Image)`
  position: absolute;
  top: -300px;
  right: -500px;
  opacity: 0.9;
  filter: brightness(105%);
  overflow: hidden;
  user-select: none;
  z-index: 0;

  ${screenMin.m`
    right: -250px;
  `}

  ${screenMin.xl`
    right: 0;
  `}
`

const StyledNav = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
`

const StyledTabs = styled(Wrapper)`
  align-items: stretch;
  display: flex;
  gap: ${GTR.S};
  overflow: auto;
  position: relative;
  z-index: 2;
`

type NavigationLinkBaseProps = {
  children: React.ReactNode
  className?: string
}

type PlaceholderNavigationLinkProps = NavigationLinkBaseProps & {
  placeholder: true
  to?: never
}

type NavigationLinkProps = NavigationLinkBaseProps & {
  placeholder?: false
  to: string
}

type Props = PlaceholderNavigationLinkProps | NavigationLinkProps

const NavigationLink = ({ placeholder, ...props }: Props) => {
  if (placeholder) {
    return <span className={props.className}>{props.children}</span>
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link {...(props as Omit<NavigationLinkProps, 'placeholder'>)} />
}

const StyledTab = styled(NavigationLink)<{
  $active?: boolean
  placeholder?: boolean
}>`
  --tab-active-border-radius: 8px;
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  color: ${({ placeholder }) => (placeholder ? COLOR.GREY.DARK : COLOR.BLACK)};
  text-decoration: none;
  padding: ${GTR.S} ${GTR.M};
  padding: ${({ placeholder }) =>
    placeholder ? `${GTR.S} 0` : `${GTR.S} ${GTR.M}`};
  position: relative;
  z-index: 1;

  ${({ $active }) =>
    $active &&
    `
    background-color: ${COLOR.WHITE};
    border-radius: var(--tab-active-border-radius) var(--tab-active-border-radius) 0 0;
  `}

  &:hover {
    text-decoration: none;
  }

  ${screenMax.s`
    margin: 0;
    text-align: center;
  `}
`

const StyledTitleWrapper = styled(Wrapper)`
  align-items: center;
  display: flex;
  flex: 1 0 0;
  gap: ${GTR.S};
`

const StyledTitle = styled.h1`
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin: 0;
  max-width: min-content;

  ${screenMin.m`
    font-size: ${FONT.SIZE.L};
    max-width: none;
  `}
`

const StyledBetaBadge = styled(Wrapper)`
  border-radius: ${BORDER.RADIUS.S};
  color: ${COLOR.BLACK};
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 1px;
  line-height: 1.5;
  position: relative;
  text-transform: uppercase;
  top: 2px; // Optical adjustment
`

const StyledLineBreak = styled.br`
  display: block;

  ${screenMin.m`
    display: none;
  `}
`

const StyledSignOutButton = styled(Button.Plain)`
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  --plain-button-flush-horizontal-boolean: 1;
`

export const Header = (): React.ReactElement => {
  const { logout } = useAuth()
  const [{ availablePartners }] = useContext(GlobalContext)

  return (
    <StyledWrapper background={COLOR.ACCENT.PRIMARY_60}>
      <StyledBackgroundImageWrapper>
        <StyledBackgroundImage
          path="textures/detail-4-dark"
          width={1365}
          height={528}
        />
      </StyledBackgroundImageWrapper>
      <StyledNav
        tag="nav"
        container
        containerPaddingTop="S"
        containerPaddingBottom="S"
      >
        <Link to="/">
          <img
            src="/icons/logo-icon.svg"
            title="Farewill"
            alt="Farewill logo"
            height={38}
            width={26}
          />
        </Link>
        <StyledTitleWrapper margin={[0, GTR.S]}>
          <StyledTitle>
            Marketing
            <StyledLineBreak /> Assistant
          </StyledTitle>
          <StyledBetaBadge
            background={COLOR.ACCENT.PRIMARY_20}
            padding={[GTR.XXS, GTR.XS]}
            paddingFromM={[GTR.XXS, GTR.S]}
            tag="span"
          >
            Beta
          </StyledBetaBadge>
          <span>
            {availablePartners.length && <PartnerName />}
            <PartnerSwitcher />
          </span>
        </StyledTitleWrapper>
        {logout && (
          <StyledSignOutButton
            type="button"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.href } })
            }
          >
            Sign out
          </StyledSignOutButton>
        )}
      </StyledNav>
      <StyledTabs container containerPaddingTop="S" containerPaddingBottom={0}>
        <StyledTab
          $active={
            window.location.pathname === (Routes.GenerateEmails as string)
          }
          to={Routes.GenerateEmails}
        >
          Email campaigns
        </StyledTab>
        <StyledTab
          $active={
            window.location.pathname === (Routes.GenerateSocial as string)
          }
          placeholder
        >
          Social Content (coming soon)
        </StyledTab>
        <StyledTab
          $active={
            window.location.pathname === (Routes.GeneratePrint as string)
          }
          placeholder
        >
          Print materials (coming soon)
        </StyledTab>
      </StyledTabs>
    </StyledWrapper>
  )
}
